import $api from '@/helpers/config-axios'

import authHeader from './auth-header'
import store from '@/store'

class HistoryService {
  async getHistory(orderId) {
    store.commit('other/onChangeLoadingItems', true)

    const {paginationPage, orderBy, sortedBy} = store.state.history

    const params = {
      page: paginationPage,
    }

    if (orderBy !== null) params['orderBy'] = orderBy
    if (sortedBy !== null) params['sortedBy'] = sortedBy

    return await $api
      .get(`orders/${orderId}/notes`, {
        params,
        headers: authHeader(),
      })
      .then(async r => {
        await store.commit('history/getAllElements', r.data)
        await store.commit('other/onChangeLoadingItems', false)
      })
  }

  async getFullHistory() {
    store.commit('other/onChangeLoadingItems', true)

    const {paginationPage, orderBy, sortedBy, filter} = store.state.history

    const params = {
      page: paginationPage,
      include: 'created_by',
    }

    if (filter.main) params['search'] = filter.main
    if (orderBy !== null) params['orderBy'] = orderBy
    if (sortedBy !== null) params['sortedBy'] = sortedBy

    return await $api
      .get(`orders/notes/all`, {
        params,
        headers: authHeader(),
      })
      .then(async r => {
        await store.commit('history/getAllElements', r.data)
        await store.commit('other/onChangeLoadingItems', false)
      })
  }

  getEvents() {
    return $api.get(`model-events/types/all`, {
      params: {
        to: 'list',
      },
      headers: authHeader(),
    })
  }

  getLog(id) {
    return $api.get(`response-logs/${id}`, {
      headers: authHeader(),
    })
  }

  modelNotes(model) {
    store.commit('other/onChangeLoadingItems', true)

    const {paginationPage, orderBy, sortedBy, filter} = store.state.history

    const params = {
      page: paginationPage,
      include: 'created_by',
      searchJoin: 'and',
    }

    if (!filter.main && model) params['search'] = `model:${model}`
    else params['search'] = model ? `${filter.main};model:${model}` : filter.main
    if (orderBy !== null) params['orderBy'] = orderBy
    if (sortedBy !== null) params['sortedBy'] = sortedBy

    return $api
      .get(`model-notes`, {
        params,
        headers: authHeader(),
      })
      .then(async r => {
        await store.commit('history/getAllElements', r.data)
        await store.commit('other/onChangeLoadingItems', false)
        return r
      })
      .finally(() => {
        store.commit('other/onChangeLoadingItems', false)
      })
  }
}

export default new HistoryService()
