<template>
  <div>
    <SidebarLeft :class="{'sidebar-left-show': sidebars.leftSidebar}">
      <FilterHistoryForm ref="filter" @on-update-table="getModelNotes($route.query.value, true)" />
    </SidebarLeft>

    <TableContainer
      ref="table-container"
      :columns="tableData"
      :counter-filter="searchCount"
      :is-archive="false"
      :is-archiving="false"
      :is-loading-items="$store.state.other.isLoadingItems"
      :is-loading-new-item="$store.state.other.isLoadingNewItem"
      :is-remove-from-archive="$can('edit', 'directory-item-delete')"
      :is-show-search="false"
      :rows="$store.state.history.elements"
      :show-add-button="false"
      :sorting-fields="['title', 'message', 'created_at', 'created_by']"
      name="history"
      title="История"
      @show-right-sidebar="() => $store.commit('sidebars/changeRightSidebar', true)"
      @update-data="getModelNotes($route.query.value)"
    ></TableContainer>

    <SidebarRight :class="{'sidebar-right--show': sidebars.rightSidebar}"></SidebarRight>
  </div>
</template>

<script>
import SidebarRight from '../components/SidebarRight'
import TableContainer from '@/components/TableContainer'
import SidebarLeft from '@/components/SidebarLeft.vue'
import ZemButton from '@/components/ui/ZemButton.vue'
import FilterHistoryForm from '@/components/History/FilterHistoryForm.vue'
import HistoryService from '@/services/history.service'
import FilterOrdersForm from '@/components/Orders/FilterOrdersForm.vue'

export default {
  components: {
    FilterOrdersForm,
    FilterHistoryForm,
    ZemButton,
    SidebarLeft,
    SidebarRight,
    TableContainer,
  },
  data() {
    return {
      tableData: [],
    }
  },

  computed: {
    searchCount() {
      return this.$store.state.history.searchCount
    },
    sidebars() {
      return this.$store.state.sidebars
    },
  },

  created() {
    this.tableData = [
      {
        label: 'ID Сущности',
        field: 'model_id',
        sortField: 'model_id',
        showInTable: true,
        sort: 0,
      },
      {
        label: 'Тип',
        field: 'type.title',
        sortField: 'title',
        showInTable: true,
        sort: 0,
      },
      {
        label: 'Сообщение',
        field: 'params.message',
        sortField: 'message',
        showInTable: true,
        sort: 0,
      },
      {
        label: 'Дата и время',
        field: 'created_at',
        sortField: 'created_at',
        showInTable: true,
        type: 'datetime',
        sort: 1,
      },
      {
        label: 'Пользователь',
        field: 'created_by.data.name',
        sortField: 'created_by',
        showInTable: true,
        sort: 0,
      },
    ]
  },

  mounted() {
    this.$store.commit('sidebars/changeLeftSidebar', true)
    this.$store.commit('sidebars/changeRightSidebar', false)
    // const {id} = this.$route.params
    // const {value} = this.$route.query
    // if (id) HistoryService.getHistory(id)
    this.getModelNotes(this.$route.query.value)
  },

  methods: {
    getModelNotes(value, isReset = false) {
      if (isReset) this.$store.commit('history/changeCurrentPage', 1)
      HistoryService.modelNotes(value).then(r => {
        this.$refs.filter.modelNotes = r.data.meta.custom.models
      })
    },
    async showRightSidebar() {
      this.$store.commit('history/itemsForDelete', [])
      this.$store.commit('sidebars/changeRightSidebar', true)
      if (this.$route.name !== 'history') await this.$router.push({name: 'history'})
    },
  },
}
</script>
